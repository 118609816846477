









import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component({
})
export default class StepNavi extends Vue {
  @Prop({ default: null })
  private active!: number;

  @Prop({ default: null })
  private data!: StepNaviData[]; // 現在のページ番号

  @Emit()
  private isActive(n: number): boolean {
    return (n <= this.active) ? true : false;
  }
}
